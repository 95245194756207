import React from "react"
import { Instagram } from "react-feather"
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TwitterIcon,
  FacebookIcon,
  WhatsappIcon,
  PinterestIcon,
  EmailIcon,
} from "react-share"

export default function SocialShareWidget({ url, title, description, image }) {
  return (
    <div className="post-meta-share-icons">
      <FacebookShareButton url={url} quote={title}>
        <FacebookIcon bgStyle={{ fill: "#333" }} round={true} size={30} />
      </FacebookShareButton>

      <TwitterShareButton url={url} title={title}>
        <TwitterIcon bgStyle={{ fill: "#333" }} round={true} size={30} />
      </TwitterShareButton>

      <a
        href={`https://www.instagram.com/?url=${url}`}
        target="_blank"
        rel="noopener"
        className="insta-share-logo"
        style={{
          background: "#333",
          padding: "5px",
          borderRadius: "100%",
          marginRight: "10px",
        }}
      >
        <Instagram color="#fff" size={20} strokeWidth={2} />
      </a>

      <PinterestShareButton url={url} title={title} media={image}>
        <PinterestIcon bgStyle={{ fill: "#333" }} round={true} size={30} />
      </PinterestShareButton>

      <WhatsappShareButton url={url} title={title} separator=":: ">
        <WhatsappIcon bgStyle={{ fill: "#333" }} round={true} size={30} />
      </WhatsappShareButton>

      <EmailShareButton url={url} subject={title} body={description}>
        <EmailIcon bgStyle={{ fill: "#333" }} round={true} size={30} />
      </EmailShareButton>
    </div>
  )
}
