import { Avatar, Box, Flex } from "@chakra-ui/core"
import styled from "@emotion/styled"
import { Value as LayoutFieldValue } from "contentful-layout-field"
import { Link } from "gatsby"
import React from "react"

import { FluidObject } from "gatsby-image"
import { BlogPostQuery } from "../../graphql-types"
import Banner from "../components/Banner"
import SEO from "../components/boilerplate/seo"
import MaxWidthGrid from "../components/Layout/MaxWidthGrid"
import LayoutBuilder from "../components/LayoutBuilder"
import PageContainer from "../components/PageContainer"
import QuizPopup from "../components/QuizPopup"
import SocialShareWidget from "../components/SocialShareWidget"
import Body from "../components/typography/Body"
import Heading from "../components/typography/Heading"
import colors from "../constants/colors"
import "../styles/css/blogPost.css"
import { createContentfulImageMap } from "../utils"
import { bp } from "../utils/MediaQueries"

interface BlogPostPageProps {
  data: BlogPostQuery
  location: Location
  pageContext: {
    layoutData: LayoutFieldValue
    images: any
  }
}

const LayoutContainer = styled(Box)`
  .embed-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    width: 100%;
    border-radius: 0.5rem;
    margin-bottom: 5rem;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .layout-block__paragraph-section {
    p,
    ul,
    ol {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 2.5rem;
    }

    li {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 1rem;
    }

    br {
      display: none;
    }

    a {
      color: ${colors.primary};
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      // font-family: Avenir Next, sans-serif;
      font-family: DINO, sans-serif;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.375rem;
      letter-spacing: -0.0125rem;
      margin-bottom: 2.5rem;
    }
  }
`

const BlogPostPage: React.FC<BlogPostPageProps> = ({
  data,
  pageContext: { layoutData },
  location,
}) => {
  let imageMap: any = undefined
  if (data.images !== undefined) {
    imageMap = createContentfulImageMap(data.images)
  }

  const defaultTitle = data.site?.siteMetadata?.title!
  const seoTitle = data.blog?.seoTitle || data.blog?.title || defaultTitle
  const seoDescription = data.blog?.seoDescription?.seoDescription || ""
  const url = location.href
  const siteUrl = data.site?.siteMetadata?.siteUrl
  const image = data.blog?.postImage?.fluid?.src || null
  const authorSlug = data.blog?.author?.slug
    ? `/author/${data.blog.author.slug}`
    : "/"

  return (
    <PageContainer className="blogPostContainer">
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={data.blog?.postImage?.fluid?.src || undefined}
        isBlogPost
        json={[
          {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                item: {
                  "@id": url,
                  name: seoTitle,
                  image: data.blog?.postImage?.fluid?.src || null,
                },
              },
            ],
          },
          {
            "@context": "http://schema.org",
            "@type": "BlogPosting",
            url,
            name: seoTitle,
            alternateName: defaultTitle,
            headline: seoTitle,
            image: {
              "@type": "ImageObject",
              url: image,
            },
            description: seoDescription,
            author: {
              "@type": "Person",
              name: data.blog?.author?.name,
            },
            publisher: {
              "@type": "Organization",
              url: siteUrl,
              logo: `${siteUrl}/images/sixthreezero_Logo.png`,
              name: "sixthreezero",
            },
            mainEntityOfPage: {
              "@type": "WebSite",
              "@id": siteUrl,
            },
            datePublished: data.blog?.createdOn,
            dateModified: data.blog?.updatedAt,
          },
        ]}
        location={location}
      />
      {data.blog?.banner?.fluid && (
        <Banner
          image={data.blog?.banner?.fluid as FluidObject}
          mobileImage={
            (data.blog?.mobileBanner?.fluid as FluidObject) || undefined
          }
          url={data.blog?.bannerSlug}
          paddingTop={bp(0, 0)}
        />
      )}
      <MaxWidthGrid>
        <Box gridColumn={bp("1/end", "3/13")} pt="30px">
          <Heading as="h1" size="2" fontWeight="bold" mb="1.375rem">
            {data.blog?.title}
          </Heading>
          <Flex mb={bp("1.5rem", "4.75rem")} justifyContent="space-between">
            <Flex>
              {data.blog?.author && (
                <Link to={authorSlug}>
                  <Avatar
                    size="md"
                    bg={
                      data.blog.author?.photo?.fixed?.src ? "white" : "primary"
                    }
                    color="white"
                    name={data.blog.author?.name || "N/A"}
                    src={data.blog.author?.photo?.fixed?.src}
                    mr="0.9375rem"
                  />
                </Link>
              )}
              <Flex flexDir="column">
                {data.blog?.author?.name && (
                  <Link to={authorSlug}>
                    <Body fontWeight="bold" className="blogAuthorName">
                      {data.blog.author?.name}
                    </Body>
                  </Link>
                )}
                {data.blog?.updatedAt && (
                  <Body color="dawn" className="blogCreatedDate">
                    Updated On: {data.blog.updatedAt}
                  </Body>
                )}
              </Flex>
            </Flex>
            {/* Share Desktop */}
            <Flex
              display={bp("none", "flex")}
              style={{
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <span className="share-button-text">Share</span>
              <SocialShareWidget
                url={url}
                title={data.blog?.title}
                description={seoDescription}
                image={image}
              />
            </Flex>
            {/* End Share Desktop */}
          </Flex>
          {/* Share Mobile */}
          <Flex
            display={bp("flex", "none")}
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30px",
              gap: "15px",
            }}
            mb={bp("4.75rem", "1.5rem")}
          >
            <span className="share-button-text">Share</span>
            <SocialShareWidget
              url={url}
              title={data.blog?.title}
              description={seoDescription}
              image={image}
            />
          </Flex>
          {/* End Share Mobile */}
        </Box>
      </MaxWidthGrid>
      <LayoutContainer>
        <LayoutBuilder data={layoutData} imageMap={imageMap} />
      </LayoutContainer>

      <QuizPopup />
    </PageContainer>
  )
}

export default BlogPostPage
