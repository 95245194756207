import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { BaseDispatch, BaseRootState } from "../../redux/store"
import { bp } from "../../utils/MediaQueries"
import BodyFitQuizFullScreen from "../BodyFitQuizFullScreen"

export default function QuizPopup() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isOpenNow, setIsOpenNow] = useState(false)

  function closeModal() {
    setIsOpenNow(false)
  }
  // Redux
  const dispatch = useDispatch<BaseDispatch>()
  const {
    quiz: { currentQuestionIndex, questions, quizProgressState, answers },
    sidebar: { isOpen: sidebarOpen },
  } = useSelector((state: BaseRootState) => state)

  const globalConfigData = useStaticQuery(graphql`
    query {
      globalConfig: contentfulGlobalConfiguration {
        enableBodyFitPopup
        bodyFitPopupDelaySeconds
      }
    }
  `)

  function secondsToMilliseconds(seconds) {
    return seconds * 1000
  }

  const enableBodyFitPopup =
    globalConfigData?.globalConfig?.enableBodyFitPopup || false
  const bodyFitPopupDelaySeconds =
    globalConfigData?.globalConfig?.bodyFitPopupDelaySeconds || 5

  useEffect(() => {
    if (enableBodyFitPopup) {
      setTimeout(
        () => setIsOpenNow(true),
        secondsToMilliseconds(bodyFitPopupDelaySeconds)
      )
    }
  }, [globalConfigData])

  return (
    <Modal isOpen={isOpenNow} onClose={closeModal} size={"xl"} isCentered>
      <ModalOverlay />
      <ModalContent
        minH={bp("auto", "80vh")}
        maxH={bp("96vh", "85vh")}
        maxW={bp("90vw", "80vw")}
        height={bp("auto", "100%")}
        overflow={bp("scroll", "unset")}
        width="100%"
        className="BodyFitPopupModule"
        background={"#005f78"}
        borderRadius="10px"
      >
        <ModalHeader></ModalHeader>
        <ModalCloseButton color={"#fff"} />
        <ModalBody>
          <BodyFitQuizFullScreen
            isPopup={true}
            // locationState={location?.state}
            currentPagePath={"/blogs"}
            isSidebarOpen={sidebarOpen}
            toggleSidebar={async () => {
              await dispatch.sidebar.setAnimate(true)
              dispatch.sidebar.toggleOpen()
            }}
            isMobileQuizDrawerOpen={isOpen}
            onClose={() => {
              if (quizProgressState === "inprogress") {
                dispatch.quiz.setProgressState("paused")
              }
              onClose()
            }}
            onOpen={onOpen}
            currentQuestionNumber={currentQuestionIndex + 1}
            totalQuestionNumber={questions.length}
            canGoToNextQuestion={
              answers[questions[currentQuestionIndex]?.questionId] !== undefined
            }
            onNextQuestion={() => {
              if (
                answers[questions[currentQuestionIndex].questionId] !==
                undefined
              ) {
                dispatch.quiz.nextQuestion()
              }
            }}
            onPrevQuestion={dispatch.quiz.prevQuestion}
            // quizIntroText={quizIntroText}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
