import { graphql } from "gatsby"
import BlogPostPage from "../views/BlogPostPage"

export const query = graphql`
  query BlogPost($slug: String!, $images: [String!]) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    images: allContentfulAsset(filter: { contentful_id: { in: $images } }) {
      edges {
        node {
          contentful_id
          fluid(maxWidth: 1000, quality: 85) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
    blog: contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      createdOn(formatString: "MMMM D, YYYY")
      updatedAt(formatString: "MMMM D, YYYY")
      author {
        name
        slug
        photo {
          fixed(width: 56, height: 56) {
            src
          }
        }
      }
      tags
      banner {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      mobileBanner {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      bannerSlug
      postImage {
        fluid(maxWidth: 1000, quality: 85) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      seoTitle
      seoDescription {
        seoDescription
      }
    }
  }
`

export default BlogPostPage
